import React from "react";

import { Description, Service, IconWrapper, Text, Title } from "./styled";

const ServiceDetail = (props) => {
  const Icon = props.icon;
  return (
    <Service>
      <IconWrapper>
        <Icon style={{ width: "80px", height: "80px" }} />
      </IconWrapper>
      <Description>
        <Title>{props.servicetitle}</Title>
        <Text>{props.paragraph}</Text>
      </Description>
    </Service>
  );
};
export default ServiceDetail;
