import React from "react";
import { graphql } from "gatsby";
import About from "../components/About";
import Clients from "../components/Clients";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Service from "../components/Service";
import Teaser from "../components/Teaser";
import Work from "../components/Work";
import TemplateWrapper from "../layouts/";
 
const IndexPage = ({ data }) => {
  let projects = data.allMarkdownRemark.edges;
  return (
    <TemplateWrapper data={data}>
      <div>
        <Teaser id="teaser" data={data.site.siteMetadata.content.teaser} />
        <Service id="service" data={data.site.siteMetadata.content.service} />
        <Work
          id="work"
          data={data.site.siteMetadata.content.work}
          projectsData={projects}
        />
        <Clients id="clients" data={data.site.siteMetadata.content.clients} />
        <About id="about" data={data.site.siteMetadata.content.about} />
        <Contact id="contact" data={data.site.siteMetadata.content.contact} />

        <Footer data={data.site.siteMetadata.content.footer} />
      </div>
    </TemplateWrapper>
  );
};

export default IndexPage;

export const query = graphql`
  query Query {
    site {
      siteMetadata {
        content {
          teaser {
            title
            headline
            subheadline
            paragraph
          }
          about {
            title
            headline
            subheadline
            paragraph
            paragraph2
          }
          service {
            title
            headline
            subheadline
          }
          work {
            title
            headline
            subheadline
          }
          clients {
            title
            headline
            subheadline
          }
          contact {
            title
            headline
            subheadline
          }
          footer {
            left
            center
            imprint
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___id], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            id
            link
            shortDescription
            companyName
            color
            workType
            year
            featuredImage {
              id
              childImageSharp {
                fluid {
                  srcSet
                  srcSetWebp
                  base64
                }
              }
            }
          }
        }
      }
    }
  }
`;
