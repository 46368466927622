import React from "react";
import { Link } from "gatsby";
import { FooterContainer, Brand } from "./styled";

const Footer = ({ data }) => {
  return (
    <FooterContainer>
      <Brand>Brains & Pixels {new Date().getFullYear()}</Brand>
      <Link to="/imprint/">{data.imprint}</Link>
    </FooterContainer>
  );
};

export default Footer;
