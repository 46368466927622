import styled from "styled-components";
import Container from "../Container";

export const AboutContainer = styled(Container)`
  padding-bottom: 120px;
  @media (min-width: 1280px) {
    padding-bottom: 120px;
  }
`;

export const TextCol = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 760px) {
    width: 50%;
    padding-right: 50px;
  }
`;

export const ImageCol = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 760px) {
    width: 50%;
    padding-left: 50px;
  }
  @media (min-width: 1280px) {
    width: 50%;
    padding-left: 100px;
  }
`;
