import React, { useEffect, useState, useRef } from "react";
import Container from "../Container";
import { TeaserElement, Steps, Outer, Text } from "./styled";
import { One, Two, Three } from "./steps";
import useOnScreen from "../Service/useOnscreen";
import { ImageWrapper } from "./styled";

const Teaser = ({ data, id }) => {
  const [one, startOne] = useState(false);
  const [two, startTwo] = useState(false);
  const [three, startThree] = useState(false);

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    const timerOne = setTimeout(() => {
      isVisible && startOne(true);
    }, 500);
    const timerTwo = setTimeout(() => {
      isVisible && startTwo(true);
    }, 1000);
    const timerThree = setTimeout(() => {
      isVisible && startThree(true);
    }, 1500);
    if (!isVisible) {
      startOne(false);
      startTwo(false);
      startThree(false);
      clearTimeout(timerOne);
      clearTimeout(timerTwo);
      clearTimeout(timerThree);
    }
    return () => {
      clearTimeout(timerOne);
      clearTimeout(timerTwo);
      clearTimeout(timerThree);
    };
  }, [isVisible]);

  return (
    <Container id={id}>
      <div style={{ width: "100%" }} ref={ref}>
        <TeaserElement>
          <Text>
            <h5>{data.title}</h5>
            <h1>{data.headline}</h1>
            <h3>{data.subheadline}</h3>
            <p style={{ paddingBottom: "40px" }}>{data.paragraph}</p>
          </Text>
          <Outer>
            <Steps>
              <ImageWrapper>{one && <One />}</ImageWrapper>
              <ImageWrapper>{two && <Two />}</ImageWrapper>
              <ImageWrapper>{three && <Three />}</ImageWrapper>
            </Steps>
          </Outer>
        </TeaserElement>
      </div>
    </Container>
  );
};
export default Teaser;
