import React from "react";
import { ContactContainer, Tel, Mail } from "./styled.js";

const Contact = ({ data, id }) => (
  <ContactContainer id={id}>
    <div>
      <h5>{data.title}</h5>
      <h2>{data.headline}</h2>
      <h3>{data.subheadline}</h3>
      <Tel href="tel:0160 225 35 07">0160 225 35 07</Tel>
      <Mail href="mailto:hello@brainsandpixels.com">
        hello@brainsandpixels.com
      </Mail>
    </div>
  </ContactContainer>
);
export default Contact;
