import styled from "styled-components";

export const Description = styled.div`
  flex: 1;
  padding-bottom: 40px;
`;

export const Service = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  @media (min-width: 760px) {
    flex-direction: row;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  @media (min-width: 760px) {
    padding-bottom: initial;
    width: 120px;
    padding-bottom: 0;
    padding-top: 20px;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 10px 0;
`;

export const Title = styled.h3`
  margin: 0;
`;
