import styled from "styled-components";
import Container from "../Container";

export const ContactContainer = styled(Container)`
  padding-bottom: 80px;
  & > div {
    @media (min-width: 1280px) {
      justify-content: space-between;
      padding-bottom: 120px;
    }
  }
`;

export const Tel = styled.a`
  font-size: 2.5rem;
  line-height: 2.5rem;
  padding-top: 40px;
  padding-bottom: 10px;
  display: block;
  @media (min-width: 760px) {
    padding-bottom: 20px;
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
`;

export const Mail = styled.a`
  font-size: 1.25rem;
  line-height: 1.25rem;
  display: block;
  @media (min-width: 760px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
`;
