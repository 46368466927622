import React from "react";
import { AboutContainer, TextCol, ImageCol } from "./styled";

const About = ({ data, id }) => {
  return (
    <AboutContainer id={id}>
      <TextCol>
        <h5>{data.title}</h5>
        <h2>{data.headline}</h2>
        <h3>{data.subheadline}</h3>
        <p>{data.paragraph}</p>
        <p>{data.paragraph2}</p>
      </TextCol>
      <ImageCol>
        <img alt="test" style={{ width: "100%", height: "auto" }} src="./phil.webp" />
      </ImageCol>
    </AboutContainer>
  );
};

export default About;
