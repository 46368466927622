import React, { useRef, useEffect } from "react";
import Container from "../Container";
import ServiceDetail from "./ServiceDetail";
import { AnalyseIcon, BrandingIcon } from "../Icons.js";
import { UiIcon } from "../Icons.js";
import { Services } from "./styled";
import useOnScreen from "./useOnscreen";
import { useTrail, a } from "react-spring";

const Service = ({ data, id }) => {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  const [trail, set] = useTrail(3, () => ({
    duration: 3000,
    opacity: 0,
    transform: "translate3d(-40px,0,0)",
  }));

  useEffect(() => {
    isVisible && set({ opacity: 1, transform: "translate3d(0,0,0)" });
  }, [isVisible, set]);

  return (
    <Container id={id}>
      <div>
        <h5>{data.title}</h5>
        <h2>{data.headline}</h2>
        <h3>{data.subheadline}</h3>
      </div>

      <Services>
        <a.div style={trail[0]}>
          <ServiceDetail
            icon={AnalyseIcon}
            servicetitle="User Experience Design"
            paragraph="Ich analysiere Ihre Lösung & Nutzerdaten und vergleiche diese auch mit anderen Branchen. Basierend auf dem Briefing und den Daten wird eine Informationsarchitektur erstellt, mit Hilfe dieser dann Wireframes entwickelt werden."
          />
        </a.div>
        <a.div style={trail[1]} ref={ref}>
          <ServiceDetail
            icon={BrandingIcon}
            servicetitle="Konzept & Branding"
            paragraph="Ich entwickle ein für den Nutzer durchgehendes logisches & visuelles Konzept. Wenn gewüscht, verbinde ich dieses nahtlos mit einem neuen Markenauftritt."
          />
        </a.div>
        <a.div style={trail[2]}>
          <ServiceDetail
            icon={UiIcon}
            servicetitle="User Interface Design"
            paragraph="Ich gestalte anhand der vorher entwickelten Wireframes das Interface, damit Ihre Produkt die Aufmerksamkeit & Professionalität bekommt, die sie verdient."
          />
        </a.div>
      </Services>
    </Container>
  );
};
export default Service;
