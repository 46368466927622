import styled from "styled-components";
import Container from "../Container";

export const FooterContainer = styled(Container)`
  padding-bottom: 40px;
  @media (min-width: 760px) {
    & > div {
      justify-content: space-between;
    }
  }
  @media (min-width: 1920px) {
    max-width: 1170px;
  }
`;

export const Brand = styled.div`
  padding-bottom: 20px;
  width: 100%;
  @media (min-width: 760px) {
    width: initial;
    padding-bottom: 0;
  }
`;
