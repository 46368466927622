import React from "react";
import Animate from "./animate";

export const One = () => (
  <Animate visible={true}>
    <svg viewBox="0 0 280 560" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.1875"
        y="2.1875"
        width="275.625"
        height="555.625"
        rx="15.3125"
        stroke="#FAFAFA"
        strokeWidth="4.375"
      />
      <rect y="498.75" width="280" height="4.375" fill="#FAFAFA" />
      <circle
        cx="26.25"
        cy="26.25"
        r="6.5625"
        stroke="#FAFAFA"
        strokeWidth="4.375"
      />
    </svg>
  </Animate>
);

export const Two = () => (
  <Animate>
    <svg viewBox="0 0 280 560" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.18799"
        y="2.1875"
        width="275.625"
        height="555.625"
        rx="15.3125"
        stroke="#FAFAFA"
        strokeWidth="4.375"
      />
      <rect
        x="28.4375"
        y="54.6875"
        width="223.125"
        height="411.25"
        rx="15.3125"
        stroke="#FAFAFA"
        strokeWidth="4.375"
      />
      <rect y="498.75" width="280" height="4.375" fill="#FAFAFA" />
      <rect x="26.25" y="170.625" width="227.5" height="4.375" fill="#FAFAFA" />
      <rect x="48.125" y="74.375" width="75.25" height="17.5" fill="#FAFAFA" />
      <rect x="48.125" y="100.625" width="143.5" height="17.5" fill="#FAFAFA" />
      <rect
        x="48.125"
        y="135.625"
        width="183.75"
        height="4.375"
        fill="#FAFAFA"
      />
      <rect
        x="48.125"
        y="148.75"
        width="183.75"
        height="4.375"
        fill="#FAFAFA"
      />
      <circle
        cx="26.25"
        cy="26.25"
        r="6.5625"
        stroke="#FAFAFA"
        strokeWidth="4.375"
      />
      <path
        d="M28.875 172.813L247.625 460.25"
        stroke="#FAFAFA"
        strokeWidth="0.875"
      />
      <path
        d="M247.625 172.812L28.875 460.25"
        stroke="#FAFAFA"
        strokeWidth="0.875"
      />
      <rect
        x="236.25"
        y="520.625"
        width="17.5"
        height="17.5"
        rx="3.5"
        fill="#FAFAFA"
      />
      <rect
        x="166.25"
        y="520.625"
        width="17.5"
        height="17.5"
        rx="3.5"
        fill="#FAFAFA"
      />
      <rect
        x="96.25"
        y="520.625"
        width="17.5"
        height="17.5"
        rx="3.5"
        fill="#FAFAFA"
      />
      <rect
        x="26.25"
        y="520.625"
        width="17.5"
        height="17.5"
        rx="3.5"
        fill="#FAFAFA"
      />
    </svg>
  </Animate>
);

export const Three = () => (
  <Animate>
    <img src="/3.webp" alt="" />
  </Animate>
);
