import { useState, useEffect } from "react";

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer =
    typeof window !== "undefined" &&
    new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

  useEffect(() => {
    if (!observer || !ref || !ref.current) {
      return;
    }
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};

export default useOnScreen;
