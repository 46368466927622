import styled from "styled-components";

export const TeaserElement = styled.div`
  padding-bottom: 80px;
  @media (min-width: 760px) {
    padding-bottom: 120px;
    padding-top: 20px;
  }
`;

export const Outer = styled.div`
  position: relative;
  @media (min-width: 760px) {
    margin: 0;
    text-align: left;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  &:first-child {
    position: relative;
  }
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 560px;
  & svg,
  & img {
    height: 560px;
    width: auto;
  }
  @media (min-width: 760px) {
    & svg,
    & img {
      width: 100%;
      height: auto;
    }
    position: initial;
    left: initial;
    margin-left: initial;
    margin-right: initial;
    width: 190px;
    height: 380px;
  }
  @media (min-width: 1040px) {
    width: 280px;
    height: 560px;
  }
  @media (min-width: 1920px) {
    width: 320px;
    height: 640px;
  }
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  > div {
    box-sizing: border-box;
  }
  @media (min-width: 760px) {
    justify-content: space-between;
  }
`;

export const Text = styled.div`
  @media (min-width: 1280px) {
    max-width: 870px;
  }
`;
