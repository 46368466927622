import React, { useEffect, useState } from "react";
import Container from "../Container";
import { Logo1und1, LogoAb, LogoBn, LogoChrono, LogoVx } from "../Icons.js";
import Slider from "react-slick";
import { SliderElement } from "./styled";

const Clients = ({ data, id }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    typeof window !== "undefined" && setIsMobile(window.innerWidth <= 760);
  }, []);

  const settings = {
    dots: false,
    infinite: isMobile,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: isMobile,
    swipeToSlide: isMobile,
    variableWidth: true,
    autoplay: isMobile,
    autoplaySpeed: 2000,
  };
  return (
    <Container id={id}>
      <div>
        <h5>{data.title}</h5>
        <h2>{data.headline}</h2>
        <h3>{data.subheadline}</h3>
      </div>
      <SliderElement>
        <Slider {...settings}>
          <div>
            <Logo1und1 />
          </div>
          <div>
            <LogoAb />
          </div>
          <div>
            <LogoBn />
          </div>
          <div>
            <LogoChrono />
          </div>
          <div>
            <LogoVx />
          </div>
        </Slider>
      </SliderElement>
    </Container>
  );
};

export default Clients;
