import styled from "styled-components";
import Container from "../Container";

export const WorkContainer = styled(Container)`
  padding-bottom: 80px;
  @media (min-width: 1280px) {
    padding-bottom: 80px;
  }
`;

export const WorkElement = styled.div`
  padding-bottom: 40px;
  @media (min-width: 1280px) {
    padding-bottom: 40px;
  }
`;

export const Desktop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  display: none;
  @media (min-width: 1280px) {
    display: block;
  }
`;

export const Dots = styled.div``;

export const Mobile = styled.div`
  width: 100%;
  @media (min-width: 1280px) {
    display: none;
  }
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0 -20px;
    padding: 0;
    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }

    display: block;

    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
      display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
    }
  }

  .slick-prev {
    left: -25px;
    [dir="rtl"] & {
      left: auto;
      right: -25px;
    }
  }

  .slick-next {
    right: -25px;
    [dir="rtl"] & {
      left: -25px;
      right: auto;
    }
  }

  /* Dots */

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-top: 20px;
    width: 100%;
    li {
      background: #000000;
      position: relative;
      display: inline-block;
      height: 8px;
      width: 8px;
      margin: 0 5px;
      padding: 0;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      button {
        display: none;
      }
      &.slick-active {
        background: #ffffff;
      }
    }
  }
`;
