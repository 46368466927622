import React from "react";
import { Link } from "gatsby";
import * as Tile from "./styled";
import { LinkIcon } from "../../Icons.js";

const ProjectTile = ({
  featuredImage,
  companyName,
  shortDescription,
  slug,
}) => {
  return (
    <Tile.Wrapper>
      <Tile.Container>
        <Link to={slug}>
          <Tile.ImageWrapper>
            <Tile.Image fluid={featuredImage.childImageSharp.fluid} />
          </Tile.ImageWrapper>
          <Tile.ActionInfo>
            <Tile.Text>
              <Tile.CompanyName>{companyName}</Tile.CompanyName>
              <Tile.ShortDescription>{shortDescription}</Tile.ShortDescription>
            </Tile.Text>
            <div>
              <LinkIcon fill="#222" stroke="#222"/>
            </div>
          </Tile.ActionInfo>
        </Link>
      </Tile.Container>
    </Tile.Wrapper>
  );
};

export default ProjectTile;
