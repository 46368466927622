import React from "react";
import { useSpring, animated, config } from "react-spring";

const Animate = ({ children, visible }) => {
  const style = useSpring({
    config: config.slow,
    from: { opacity: visible ? 1 : 0 },
    to: { opacity: 1 },
  });

  return <animated.div style={style}>{children}</animated.div>;
};

export default Animate;
