import styled from "styled-components";
import GatsbyImage from "gatsby-image";

export const Wrapper = styled.div`
  padding-bottom: 40px;
  display: flex;
  width: 280px;
  padding: 0 20px;

  @media (min-width: 1280px) {
    width: 100%;
    padding: 0 0 40px 0;
  }
`;

export const Container = styled.div`
  padding: 20px 10px 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 520px;
  background: #fafafa;
  border-radius: 10px;
  color: #000000;
  & > a {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  @media (min-width: 1280px) {
    padding: 0 40px 0 0;
    height: 320px;
    & > a {
      flex-direction: row;
    }
  }
`;

export const ImageWrapper = styled.div`
  height: 200px;
  width: 100%;
  order: 2;
  @media (min-width: 1280px) {
    order: 1;
    flex: 1;
    height: 320px;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media (min-width: 1280px) {
    width: 460px;
  }
`;

export const ActionInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  justify-content: flex-start;
  align-items: flex-end;
  @media (min-width: 1280px) {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin-left: auto;
    padding: 0;
    order: 2;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  @media (min-width: 1280px) {
    padding-right: 40px;
    padding-bottom: 0;
  }
`;

export const CompanyName = styled.div`
  font-family: Gilroy-Bold;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  text-align: right;
  color: #000000;
  padding-bottom: 20px;
  @media (min-width: 1280px) {
    padding-bottom: 0;
  }
`;

export const ShortDescription = styled.div`
  font-family: Gilroy-Light;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: right;
  color: #000000;
`;

