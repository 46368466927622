import React from "react";
import Slider from "react-slick";
import ProjectTile from "./ProjectTile";
import { WorkContainer, Mobile, Desktop, Dots, WorkElement } from "./styled";

const Work = ({ data, projectsData, id }) => {
  const projects = projectsData.map((project, i) => (
    <ProjectTile
      key={i}
      slug={project.node.fields.slug}
      year={project.node.frontmatter.year}
      workType={project.node.frontmatter.workType}
      companyName={project.node.frontmatter.companyName}
      featuredImage={project.node.frontmatter.featuredImage}
      shortDescription={project.node.frontmatter.shortDescription}
    />
  ));
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    appendDots: (dots) => (
      <Dots>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </Dots>
    ),
  };
  return (
    <WorkContainer id={id}>
      <WorkElement>
        <h5>{data.title}</h5>
        <h2>{data.headline}</h2>
        <h3>{data.subheadline}</h3>
      </WorkElement>
      <Desktop>{projects}</Desktop>
      <Mobile>
        <Slider {...settings}>{projects}</Slider>
      </Mobile>
    </WorkContainer>
  );
};

export default Work;
